@import url('https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap');

.h1{
    font-weight: 200;
}

h5{
    font-size: 1.1rem;
    padding-left: 10px;
}

#container{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Titillium Web;
    
    
}

#section-login{
    width: 100%;
    display: flex;
    justify-content: end;
    margin: 0%;
    align-items: center;
}

.restante{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  
  #pendentes-e-historico{
    width: 40%;
    height: 60vh;
    margin: 2%;
}

#alteracoes{
    border: 1px solid #d7d7d7;
    overflow-y: auto;
    max-height:  380px;
}

#janelaModificacoes{
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border-radius: 3px;
    border: 1px solid rgb(204, 204, 204) ;
    
}

#teste{
    margin: 2%;
    width: 30%;
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

#materialPadrao{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d7d7d7;
    flex: 1;
    display: flex;
    overflow-y: auto;
    max-height: 300px; /* Defina uma altura máxima para a área rolável */
    margin: 0 10px;
    max-width: 95%;
    
}

#materialModificado{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d7d7d7;
    color: #000000;
    flex: 1 1;
    display: flex;
    overflow-y: auto;
    max-height: 300px;
    margin: 0 10px;
    max-width: 95%;
}

/*BOTÕES*/

#boxBotoes{
    display: flex;
    align-items: center;
    padding-top: 20px;
    justify-content: space-evenly;
    padding-left: 2%;
    padding-right: 2%;
}

.botaoAcao{
    display: flex; 
    justify-content: center; 
    align-items: center; 
    padding-bottom: 2%;
}

.botaobotaoAcao {
    display: flex; 
    justify-content: center; 
    align-items: center; 
    width: 90%;
    border-radius: 10px;
    font-size: small;
    height: 4vh;
}

.botaobotaoAcao:disabled {
    cursor: not-allowed;
    background-color: lightgray; /* Cor de fundo para indicar que está desabilitado (opcional) */
}

#botaoDescartar{
    /* border-radius: 1px; */
    background-color: #ffffff;
    color: rgb(0, 0, 0) ;
    border: 1px solid black;

}

#botaoAtualizar{
    /* border-radius: 5px; */
    background-color: #FF4400;
    color: aliceblue ;
    border-color: #FF4400;
    border: 1px solid #FF4400;
}

#botaoAguardar{
    /* border-radius: 5px; */
    background-color: #000000;
    color:aliceblue ;
    border: 1px solid black;
    
}

.max-width-content {
    max-width: 100%;
    word-wrap: break-word; /* Esta propriedade permite que as palavras sejam quebradas para caber dentro do contêiner */
  }
  
  

#variavelEstado{
    width: 100%;
    display: flex;
    background-color: rgba(79, 71, 71, 0.005);
}
.bt-more-div{
    display: flex;
    justify-content: flex-end;
    padding-bottom: 2%;
}

#listaReduzida{
    border: 1px solid #d7d7d7;
    margin: 1%;
}

#more{
    border: none;
    background: transparent;
    color: cornflowerblue;
}

.container-rolavel {
    display: flex;
    overflow-x: hidden;
    flex-direction: column;
    border-radius: 10px;
  } 

  /* Estilizando a barra de rolagem */
::-webkit-scrollbar {
    width: 12px; /* Largura da barra de rolagem */
  }
  
  /* Estilizando a thumb (indicador de posição) */
  ::-webkit-scrollbar-thumb {
    background-color: #c4c4c4; /* Cor de fundo da thumb */
    border-radius: 6px; /* Arredonda as bordas da thumb */
  }
  
  .css-1p823my-MuiListItem-root.Mui-selected {
    background-color: transparent !important;
}
.css-bshv44-MuiButtonBase-root-MuiListItem-root.Mui-selected {
    background-color: transparent !important;
}