
.modal {
  position: fixed;
  /* left: 75%; */
  bottom: 5%;
}

.modal2 {
  position: fixed;
  right: 8%;
  bottom: 2%;
  width: 30%;
}

.modal3 {
  width: 100%;
  margin: 0;
}

.bottom-right-modal {
  position: fixed!important;
  bottom: 0;
  right: 0;
  width: 30%!important;
}


@keyframes fadeSlideIn {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeSlideOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-20px);
  }
}

.fade-slide-enter {
  animation: fadeSlideIn 0.5s ease-in-out;
}

.fade-slide-exit {
  animation: fadeSlideOut 0.5s ease-in-out;
}
