@import url('https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap');


#total{
    width: 100%;
    font-family: Titillium Web;
}

#right{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.save-bt{

}

.comp-bt{

}

.bt-right{
    
}