@import url('https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap');

#facilitadores-container{
  height: auto;
}

#topo{
    width: 100%;
    height: 9vh;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin: 0;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.col-logo{
    padding-left: 10%;
}

.adm{
    padding-left: 15%;
}

.sair{
    padding-left: 0%;
}

#modulo {
 
  
  padding: 10px;
  font-size: 10px;
  
  
  display: flex;
  
  align-items: flex-start;
}

#compilar {
  padding-right: 10%;
  display: flex;
  align-items: normal;
  flex-direction: column;
  justify-content: space-around;
  background-color: #FF5003 !important;
  color: #fff !important;
}

#botoes_adicionar {
  display: flex;
  align-items: normal;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

#frame_slide {
  width: 100%;
  height: 100%;
  border: none;
  overflow-y: scroll;
}

#slides_exercicios {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
}

/* Estilos para o iframe */
#frame_slide_descoberta_guiada {
  width: 100%;
  height: 100%;
  border: none;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

/* Estilos para o iframe */
#frame_slide_hands_on {
  width: 100%;
  height: 100%;
  border: none;
  overflow-y: scroll;
}

/* Estilos do botão flutuante */
.botao-flutuante {
  position: fixed;
  bottom: 5%;
  left: 20px;
  z-index: 9999;
  font-family: Titillium Web;
  background-color: #FF5003;
  border-radius: 4px;
}

.botao-flutuante a {
  display: inline-block;
  /* background-color: #FF5003; */
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
}

.botao-flutuante-adm {
  position: fixed;
  bottom: 5%;
  left: 120px;
  z-index: 9999;
  font-family: Titillium Web;
  background-color: #FF5003;
  border-radius: 4px;
}

.botao-flutuante-adm a {
  display: inline-block;
  /* background-color: #FF5003; */
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
}

.bloco_praticas {
  width: 100%;
  height: 100%;
  display: flex; 
}

#section-logo{
  width: 15%;
  display: flex;
  justify-content: center;
  margin: 0%;
  align-items: center;
}

#logo-app-img{
  width: 100px; 
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: black 5px dashed;
}

.div-total-image{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.manipular-button {
  border: none !important;
  background: transparent !important;
  color: gray;
  text-decoration: underline !important;
}
