.total-hist {
    display: flex;
    flex-direction: column;
  }
  
  .container-rolavel {
    display: flex;
    overflow-x: hidden;
    flex-direction: column;
  } 
  
  .metade-container {
    flex: 1 1;
    overflow-y: auto;
    max-height: 150px;
    margin: 0 20px;
    border-radius: 10px;
    background-color: rgb(241, 241, 241);
  }
  
  .metade-container2 {
    flex: 1 1;
    overflow-y: auto;
    max-height: 150px;
    margin: 0 20px;
    border-radius: 3px;
    background-color: rgb(255, 255, 255);
    
  }

 
