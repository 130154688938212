.centercolumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.list-group-item-action:hover {
    background-color: #f8f9fa; /* Cor de fundo no hover */
}

.list-group-item.active {
    background-color: #007bff; /* Cor de fundo no active */
    border-color: #007bff;
    color: white; /* Cor do texto no active */
}

.ListaItens {
    max-height: 70vh;
    overflow-y: auto;
}

#SaveButtonRow {
    display: flex;
    justify-content: center;
    padding-top: 10vh;
}

.sidebutton {
    background-color: transparent;
    width: 6.5vw;
    height: 5.5vh; 
    border: 1px grey solid; 
    border-radius: .7vh;
    padding: 0px;
    display: flex;
    justify-content: center;
}

.sidebutton:disabled {
    background-color: transparent;
    border: 1px grey solid; 
}