.blocoseqpratica {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.BlocoSequenciaPraticas {
    display: flex; 
    position: relative; 
    width: 50%;
    height: 100%;
    overflow-y: scroll;
}