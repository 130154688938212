.dropdown-list {
    position: relative;
    display: flex;
    border: 1px solid #757575;
    border-radius: 1px;
    background-color: transparent;
    margin: 10%;
    font-size: medium;
    width: 200px;
    justify-content: center;
    align-items: center;
}

.selected-option {
  cursor: pointer;
}

.options {
  position: absolute;
  top: 100%;
  left: 0;
  display: block;
  /* visibility: hidden; */
  background-color: #fff;
  border: 1px solid #ccc;
  padding-left: 0px;
  padding-right: 5px;
  /* margin-top: 0px; */
  opacity: 90%;
  list-style-type: none;
  z-index: 1000;
  overflow-y: scroll;
  height: 1000%;
}

.item {
  width: 100%;
  padding-left: 2px;
  padding-right: 3px;
}

.item:hover {
  background-color: #1512121b;
  cursor: pointer;
}

/* .options.open {
  display: block;
  visibility: visible;
  z-index: 1000;
} */
