@import url('https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap');

.def{
  display: flex;
  align-items: center;
  justify-content: center ;
}

.login-container {
  width: 25%;
  height: 60vh;
  margin: 150px auto;
  background: #fff;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.title-login{
  
}

#logo-harve-login{
  width: 160px;
}

.login-container h2 {
  text-align: center;
}

.login-container input {
  width: 100%;
  padding: 10px;
  margin: 8px 0;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.login-container button {
  width: 100%;
  background: #87ef96;
  border: none;
  border-radius: 10px;
  padding: 10px;
  
}

.login-container button:hover {
  background: #75cc82;
  
}
